import { MapTableProvider } from "@uni/utility/AppContextProvider/MapTableContextProvider";
import React from "react";
const MapTable = React.lazy(() => import("./MapTable"));

export const mapTableConfig = [
  {
    path: "/mapping",
    element: (
      <MapTableProvider>
        <MapTable />
      </MapTableProvider>
    ),
  },
];
