import { CheckCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import AppCustomDrawerWrapper from "@uni/core/AppCustomDrawerWraper";
import { Col, message, Modal, Row } from "antd";
import Button from "antd/es/button/button";
import usePostDeduction from "hooks/apis/bangke/usePostDeduction";
import usePostRollback from "hooks/apis/bangke/usePostRollback";
import {
  forwardRef,
  lazy,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import AppButtonActions from "@uni/core/AppButtonAction";

const Bangke = lazy(() =>
  import("pages/determination/Determine/GenerateModal/BangKe")
);
import * as XLSX from "xlsx-js-style";
import { PERMISSIONS } from "shared/constants/AppEnums";
import { useIntl } from "react-intl";
import { getNumberAfterRVC } from "@uni/utility/Utils";
import BangkeRVCDirect from "pages/determination/Determine/GenerateModal/BangKeRVCDirect/BangkeRVCDirect";

const borderFormatCell = {
  top: { style: "thin", color: { rgb: "000000" } }, // Top border
  bottom: { style: "thin", color: { rgb: "000000" } }, // Bottom border
  left: { style: "thin", color: { rgb: "000000" } }, // Left border
  right: { style: "thin", color: { rgb: "000000" } }, // Right border
};

const formatListToNoneBorder = (array) => {
  return array.map((subItem) => {
    return typeof subItem !== "object"
      ? {
          v: subItem,
          t: "n",
          s: { border: {} },
        }
      : subItem;
  });
};

const formatNestedListToNoneBorder = (array) => {
  return array.map((item) =>
    item.map((subItem) => {
      return typeof subItem !== "object"
        ? {
            v: subItem,
            t: "s",
            s: {
              border: {},
            },
          }
        : subItem;
    })
  );
};

const transformDataDownload = (data) => {
  return data?.map((item, index) => [
    {
      v: index + 1,
      t: "n",
      s: {
        alignment: { horizontal: "center", vertical: "center" },
        border: borderFormatCell,
      },
    },
    item.item_name,
    item.hs_code,
    item.unit,
    item.bom_value?.toFixed(3),
    item.unit_price.toFixed(3),
    item.origin_price === 0 ? "" : item.origin_price.toFixed(3),
    item.non_origin_price === 0 ? "" : item.non_origin_price.toFixed(3),
    item.from_country,
    item.cd_number,
    item.cd_date,
    {
      v: item.issued_date ? item?.reference_no || "" : item.exporter || "",
      t: "n",
      s: { border: borderFormatCell },
    },
    {
      v: item.issued_date || "",
      t: "n",
      s: { border: borderFormatCell },
    },
  ]);
};

const renderRVCDirectData = (ecus_input) => [
  [
    {
      v: "II",
      t: "s",
      s: {
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    {
      v: "Chi phí nhân công trực tiếp:",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
  [
    {
      v: "1",
      t: "s",
      s: {
        border: borderFormatCell,
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Lương, thưởng",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(
        ecus_input?.direct_rvc_calculations?.salary_and_bonus?.toFixed(3) || 0
      ),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
  [
    {
      v: "2",
      t: "s",
      s: {
        border: borderFormatCell,
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Phúc lợi y tế",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(
        ecus_input?.direct_rvc_calculations?.medical_benefits?.toFixed(3) || 0
      ),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
  [
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: "Tổng II.",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(ecus_input?.direct_rvc_calculations?.total_ii?.toFixed(3) || 0),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],

  [
    {
      v: "III",
      t: "s",
      s: {
        border: borderFormatCell,
        font: {
          bold: true,
        },
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Chi phí phân bổ trực tiếp:",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
  [
    {
      v: "1",
      t: "s",
      s: {
        border: borderFormatCell,
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Phí thuê nhà xưởng",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(
        ecus_input?.direct_rvc_calculations?.factory_rental_fee?.toFixed(3) || 0
      ),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
  [
    {
      v: "2",
      t: "s",
      s: {
        border: borderFormatCell,
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Phí khấu hao phương tiện VT, máy móc, nhà xưởng, chi phí DV thuê ngoài, chi phí CCDC, CP nhân viên phân xưởng. …",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(
        ecus_input?.direct_rvc_calculations?.depreciation_cost?.toFixed(3) || 0
      ),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
  [
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: "Tổng III.",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(
        ecus_input?.direct_rvc_calculations?.total_iii?.toFixed(3) || 0
      ),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],

  [
    {
      v: "IV",
      t: "s",
      s: {
        border: borderFormatCell,
        font: {
          bold: true,
        },
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Chi phí xuất xưởng",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
  [
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: "(Tổng I+II+III):",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(
        ecus_input?.direct_rvc_calculations?.factory_outgoing_cost?.toFixed(
          3
        ) || 0
      ),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],

  [
    {
      v: "V",
      t: "s",
      s: {
        border: borderFormatCell,
        font: {
          bold: true,
        },
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Lợi nhuận",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(ecus_input?.direct_rvc_calculations?.profit?.toFixed(3) || 0),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],

  [
    {
      v: "VI",
      t: "s",
      s: {
        border: borderFormatCell,
        font: {
          bold: true,
        },
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Giá xuất xưởng",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
  [
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: "(Tổng IV+V):",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(
        ecus_input?.direct_rvc_calculations?.factory_outgoing_price?.toFixed(
          3
        ) || 0
      ),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],

  [
    {
      v: "VII",
      t: "s",
      s: {
        border: borderFormatCell,
        font: {
          bold: true,
        },
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Các chi phí khác",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
  [
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: "(chi phí vận chuyển,…)",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(
        ecus_input?.direct_rvc_calculations?.other_costs?.toFixed(3) || 0
      ),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],

  [
    {
      v: "VIII",
      t: "s",
      s: {
        border: borderFormatCell,
        font: {
          bold: true,
        },
        alignment: {
          horizontal: "right",
        },
      },
    },
    {
      v: "Trị giá FOB (VI+VII)",
      t: "s",
      s: {
        alignment: { wrapText: true },
        border: borderFormatCell,
        font: {
          bold: true,
        },
      },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    {
      v: Number(
        ecus_input?.direct_rvc_calculations?.total_FOB_value?.toFixed(3) || 0
      ),
      t: "s",
      s: { border: borderFormatCell },
    },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
    { v: "", t: "s", s: { border: borderFormatCell } },
  ],
];

const renderResult = (deminimisData, recalculate_args, ecus_input) => {
  const RVCFormResult =
    recalculate_args?.form_type === "EXW"
      ? `Hàng hoá ${
          !ecus_input?.formula_status ? "không " : ""
        }đáp ứng tiêu chí "Trị giá nguyên liệu được sử dụng đế sản xuất không vượt quá ${getNumberAfterRVC(
          recalculate_args?.criteria_value
        )}% giá trị xuất xưởng của sản phẩm"`
      : `Hàng hóa ${
          !ecus_input?.formula_status ? "không " : ""
        }đáp ứng tiêu chí "RVC40"`;

  if (recalculate_args?.form_criteria === "RVC" && ecus_input?.is_direct_rvc) {
    return "Kết luận: Hàng hóa đáp ứng tiêu chí “RVC40” ";
  }

  if (deminimisData?.is_deminimis) {
    return `Kết luận: Hàng hóa ${
      deminimisData?.formula_status ? "" : "không "
    }đáp ứng tiêu chí "${
      recalculate_args?.criteria_value
    } + De minimis ${Number(deminimisData?.formula_result.toFixed(3))}%"`;
  }

  if (ecus_input?.select_form === "AI") {
    return `Kết luận: Hàng hóa ${
      !ecus_input?.formula_status ? "không " : ""
    } đáp ứng tiêu chí “ RVC 35%+CTSH“`;
  }

  if (ecus_input?.select_form === "EV" || ecus_input?.select_form === "UKV") {
    return (
      "Kết luận: " +
      (recalculate_args?.form_criteria === "RVC"
        ? RVCFormResult
        : `Hàng hoá ${
            !ecus_input?.formula_status ? "không " : ""
          }đáp ứng tiêu chí "Sử dụng nguyên liệu từ bất kỳ Nhóm nào để sản xuất, ngoài trừ Nhóm của sản phẩm"`)
    );
  }

  return `Kết luận: Hàng hóa ${
    !ecus_input?.formula_status ? "không " : ""
  } đáp ứng tiêu chí “${recalculate_args?.criteria_value}“`;
};

const ViewHistoryModal = forwardRef(({ data: DataBangke }, ref) => {
  const { messages } = useIntl();
  const [isVisible, setIsVisible] = useState(false);
  const openModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);

  const closeModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );

  const ExportAllToExcel = () => {
    const workbook = XLSX.utils.book_new();

    const {
      bangke_data,
      recalculate_args,
      company_info,
      ecus_input,
      start_date,
    } = DataBangke || {};

    const deminimisData = {
      is_weight: DataBangke?.is_weight,
      formula_result: DataBangke?.ecus_input?.formula_result,
      formula_status: DataBangke?.ecus_input?.formula_status,
      is_deminimis: DataBangke?.is_deminimis,
    };
    const itemMap =
      bangke_data?.length > 0 ? transformDataDownload(bangke_data) : [];
    const formular = (form_criteria, form_type, sedo) => {
      if (ecus_input?.select_form === "AI") {
        return formatNestedListToNoneBorder([
          [
            "",
            {
              v: "Trị giá của nguyên liệu, phụ tùng hoặc sản phẩm không có xuất xứ AIFTA",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "+",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "Trị giá của nguyên liệu, phụ tùng hoặc sản phẩm không xác định được xuất xứ",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "x 100%",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            {
              v: "=",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            {
              v: recalculate_args?.total_non_origin_price?.toFixed(2),
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            {
              v: "x 100%",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            {
              v: "=",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            {
              v:
                ecus_input?.formula_result === 0
                  ? ecus_input?.formula_result + "%"
                  : ecus_input?.formula_result?.toFixed(2) + "%",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
          ],
          [
            "",
            {
              v: "Giá FOB",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },

            "",
            "",
            "",
            "",
            {
              v: ecus_input?.formula_nt_value?.toFixed(2),
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  top: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            "",
          ],
        ]);
      }
      if (form_criteria === "RVC" && ecus_input?.is_direct_rvc) {
        return formatNestedListToNoneBorder([
          [
            "Công thức tính RVC trực tiếp =",

            {
              v: "Chi phí nguyên liệu FTA (Trị giá CIF nguyên liệu, phụ tùng hoặc hàng hóa có xuất xứ FTA do người sản xuất mua hoặc tự sản xuất)",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "+",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: "Chi phí nhân công trực tiếp",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: "+",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: "Chi phí phân bổ trực tiếp",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: "+",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: "Các chi phí khác",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: "+",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "Lợi nhuận",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "x 100%",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            {
              v: "",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
          ],
          [
            "",
            {
              v: "Trị giá FOB",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "=",

            {
              v: recalculate_args?.total_origin_price.toFixed(3) || "",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "+",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: ecus_input?.direct_rvc_calculations?.total_ii.toFixed(3) || "",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: "+",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v:
                ecus_input?.direct_rvc_calculations?.total_iii.toFixed(3) || "",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: "+",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v:
                ecus_input?.direct_rvc_calculations?.other_costs.toFixed(3) ||
                "",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },

            {
              v: "+",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: ecus_input?.direct_rvc_calculations?.profit.toFixed(3) || "",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "x 100%",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            {
              v: "=",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            {
              v: ecus_input?.formula_result + "%",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
          ],
          [
            "",
            {
              v: ecus_input?.direct_rvc_calculations?.total_FOB_value,
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        ]);
      } else if (form_criteria === "RVC" && sedo === true) {
        return formatNestedListToNoneBorder([
          [
            {
              v: "Trị giá giá gia công",
              t: "s",
              s: {
                alignment: {
                  horizontal: "right",
                },
              },
            },
            "",
            {
              v: "=",
              t: "s",
              s: {
                alignment: { horizontal: "center" },
              },
            },
            {
              v: "Số lượng         x        Giá gia công",
              t: "s",
              s: {
                alignment: { horizontal: "center" },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            "",
            "",
            {
              v:
                ecus_input?.ecus_total_quantity +
                "           x                  " +
                recalculate_args?.progress_price,
              t: "s",
              s: {
                alignment: { horizontal: "center" },
              },
            },
            "",
            "",
            "=      " +
              Number(
                (
                  Number(ecus_input?.ecus_total_quantity) *
                  Number(recalculate_args?.progress_price)
                ).toFixed(3)
              ),
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            {
              v: "Trị giá xuất xưởng",
              t: "s",
              s: {
                alignment: { horizontal: "right" },
              },
            },
            "",
            {
              v: "=",
              t: "s",
              s: {
                alignment: { horizontal: "center" },
              },
            },
            {
              v: "Trị giá NPL có xuất xứ + Trị giá NPL không xuất xứ + Trị giá giá gia công",
              t: "s",
              s: {
                alignment: { horizontal: "center" },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            "",
            "",
            {
              v:
                recalculate_args?.total_origin_price?.toFixed(3) +
                "   +   " +
                recalculate_args?.total_non_origin_price?.toFixed(3) +
                "   +   " +
                Number(ecus_input?.ecus_total_quantity) *
                  Number(recalculate_args?.progress_price),
              t: "s",
              s: {
                alignment: { horizontal: "center" },
              },
            },
            "",
            "",
            {
              v:
                "=      " +
                (
                  recalculate_args?.total_origin_price +
                  recalculate_args?.total_non_origin_price +
                  Number(ecus_input?.ecus_total_quantity) *
                    Number(recalculate_args?.progress_price)
                ).toFixed(3),
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            {
              v: "Công thức % của giá trị nguyên liệu được sử dụng",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            "",
            {
              v: "=",
              t: "s",
              s: {
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
            {
              v: "Trị giá nguyên liệu đầu vào không có xuất xứ VN",
              t: "s",
              s: {
                alignment: { horizontal: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "",
              t: "s",
              s: {
                alignment: { horizontal: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "",
              t: "s",
              s: {
                alignment: { horizontal: "center" },
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
              },
            },
            {
              v: "= " + ecus_input?.formula_result?.toFixed(2) + "%",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },

            // {
            //   v: ecus_input?.formula_result?.toFixed(2) + "%",
            //   t: "s",
            //   s: {
            //     alignment: {
            //       horizontal: "center",
            //       vertical: "center",
            //     },
            //   },
            // },
          ],
          [
            "",
            "",
            "",
            {
              v: "Trị giá xuất xưởng",
              t: "s",
              s: {
                alignment: { horizontal: "center" },
              },
            },
            "",
            "",
            "",
            "",

            "",
            "",
            "",
            "",
            "",
          ],
        ]);
      } else if (form_criteria === "RVC" && form_type === "EXW") {
        return formatNestedListToNoneBorder([
          [
            {
              v: "RVC  =",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },
            {
              v: "Tổng trị giá hàng hoá không xuất xứ",
              t: "s",
              s: {
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
                alignment: {
                  horizontal: "center",
                },
              },
            },

            {
              v: "x  100%   = ",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            {
              v: "Trị giá",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "         =",
            {
              v: recalculate_args?.total_non_origin_price?.toFixed(2),
              t: "s",
              s: {
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
                alignment: {
                  horizontal: "center",
                },
              },
            },

            {
              v: "x  100%   = ",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },
            {
              v: ecus_input?.formula_result?.toFixed(2) + "%",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            {
              v: ecus_input?.formula_nt_value?.toFixed(2),
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        ]);
      } else if (form_criteria === "RVC" && form_type === "FOB") {
        return formatNestedListToNoneBorder([
          [
            {
              v: "RVC  =",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },

            {
              v: "Trị giá - Tổng trị giá hàng hoá không xuất xứ",
              t: "s",
              s: {
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
                alignment: {
                  horizontal: "center",
                },
              },
            },
            {
              v: "x  100%   = ",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },

            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            {
              v: "Trị giá",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "         =",
            {
              v: `${ecus_input?.formula_nt_value?.toFixed(
                2
              )} - ${recalculate_args?.total_non_origin_price?.toFixed(2)}`,
              t: "s",
              s: {
                border: {
                  bottom: { style: "thin", color: { rgb: "000000" } },
                },
                alignment: {
                  horizontal: "center",
                },
              },
            },
            {
              v: "x  100%   = ",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },
            {
              v: ecus_input?.formula_result?.toFixed(2) + "%",
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            "",
            {
              v: ecus_input?.formula_nt_value?.toFixed(2),
              t: "s",
              s: {
                alignment: {
                  horizontal: "center",
                  vertical: "center",
                },
              },
            },

            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        ]);
      } else return [];
    };

    const data = [
      [
        {
          v: "PHỤ LỤC VIII",
          t: "s",
          s: {
            font: {
              bold: true,
            },
            alignment: {
              horizontal: "center",
            },
          },
        },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
      ],
      [
        {
          v: "BẢNG KÊ KHAI HÀNG HOÁ XUẤT KHẨU ĐẠT TIÊU CHÍ CTC",
          t: "s",
          s: {
            font: {
              bold: true,
            },
            alignment: {
              horizontal: "center",
            },
          },
        },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
      ],
      [
        {
          v: "(ban hành kèm theo Thông tư số: 05/2018/TT-BTC ngày 03 tháng 04 năm 2018 quy định về xuất xứ hàng hóa)",
          t: "s",
          s: {
            font: {
              italic: true,
            },
            alignment: {
              horizontal: "center",
            },
          },
        },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
      ],
      [
        {
          v: "Tên thương nhân: ",
          t: "s",
          s: { alignment: { wrapText: true } },
        },
        { v: company_info?.company_name, t: "s" },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "Tiêu chí áp dụng: ", t: "s", s: {} },
        {
          v: recalculate_args?.criteria_value,
          t: "s",
          s: { alignment: { wrapText: true } },
        },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
      ],
      [
        { v: "Địa chỉ: ", t: "s", s: {} },
        {
          v: company_info?.company_address,
          t: "s",
          s: { alignment: { wrapText: true } },
        },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
        { v: "Tên hàng hoá: ", t: "s", s: {} },
        {
          v: ecus_input?.ecus_item_name,
          t: "s",
          s: { alignment: { wrapText: true } },
        },
        { v: "", t: "s", s: {} },
        { v: "", t: "s", s: {} },
      ],
      formatListToNoneBorder([
        "Mã số thuế: ",
        company_info?.company_tax,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Mã HS của hàng hoá: ",
        ecus_input?.ecus_hs,
        "",
        "",
      ]),
      formatListToNoneBorder([
        "Tờ khai số: ",
        `${ecus_input?.ecus_cd_number} ngày ${ecus_input?.ecus_cd_date}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Số lượng: ",
        {
          v: ecus_input?.ecus_total_quantity,
          t: "n",
          s: { alignment: { horizontal: "left" } },
        },
        "",
        "",
      ]),
      formatListToNoneBorder([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Trị giá: ",
        {
          v: Number(ecus_input?.formula_nt_value)?.toLocaleString(),
          t: "n",
          s: {
            numberFormat: "#,##0",

            alignment: { horizontal: "left", vertical: "center" },
          },
        },
        "",
        "",
      ]),
      [],
      [],
      [
        {
          v: "STT",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "Nguyên phụ liệu",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "Mã HS",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "Đơn vị tính",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "ĐM/1SP",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "Nhu cầu nguyên liệu sử dụng cho lô hàng",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        { v: "", t: "s", s: { border: borderFormatCell } },
        { v: "", t: "s", s: { border: borderFormatCell } },
        {
          v: "Nước xuất xứ",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "Tờ khai hải quan nhập khẩu/ Hoá đơn giá trị gia tăng",
          t: "s",
          s: {
            alignment: {
              horizontal: "center",
              vertical: "center",
              wrapText: true,
            },
            border: borderFormatCell,
          },
        },
        { v: "", t: "s", s: { border: borderFormatCell } },
        {
          v: "C/O ưu đãi nhập khẩu/ Bản khai báo của nhà sản xuất/nhà cung cấp nguyên liệu trong nước",
          t: "s",
          s: {
            alignment: {
              horizontal: "center",
              vertical: "center",
              wrapText: true,
            },
            border: borderFormatCell,
          },
        },
        { v: "", t: "s", s: { border: borderFormatCell } },
      ],
      [
        "",
        "",
        "",
        "",
        "",
        {
          v: "Đơn giá (CIF)",
          t: "s",
          s: {
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
            border: borderFormatCell,
          },
        },
        {
          v: "Trị giá USD",
          t: "s",
          s: {
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
            border: borderFormatCell,
          },
        },
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "Có xuất xứ FTA",
        "Không xuất xứ FTA",
        "",
        {
          v: "Số",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "Ngày",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "Số",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "Ngày",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
      ],
      [
        {
          v: "(1)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(2)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(3)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(4)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(5)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(6)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(7)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(8)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(9)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(10)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(11)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(12)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
        {
          v: "(13)",
          t: "s",
          s: {
            alignment: { horizontal: "center", vertical: "center" },
            border: borderFormatCell,
          },
        },
      ],
      ...itemMap,
      formatListToNoneBorder([
        "Tổng",
        "",
        "",
        "",
        "",
        "",
        recalculate_args?.total_origin_price?.toFixed(3),
        recalculate_args?.total_non_origin_price?.toFixed(3),
        "",
        "",
        "",
        "",
        "",
      ]),
      ...(recalculate_args?.form_criteria === "RVC" && ecus_input?.is_direct_rvc
        ? renderRVCDirectData(ecus_input)
        : []),
      ...formular(
        recalculate_args?.form_criteria,
        recalculate_args?.form_type,
        !!recalculate_args?.progress_price
      ),
      ...(deminimisData?.is_deminimis
        ? formatNestedListToNoneBorder([
            [
              "",
              {
                v: "Tỷ lệ De Minimis = ",
                t: "s",
                s: {
                  alignment: { horizontal: "center", vertical: "center" },
                },
              },
              `${
                deminimisData?.is_weight
                  ? "Tổng trọng lượng của các nguyên liệu không có xuất xứ và không đáp ứng tiêu chí CTC"
                  : "Tổng trị giá của các nguyên liệu không có xuất xứ và không đáp ứng tiêu chí CTC"
              }`,
              "",
              "",
              "",
              {
                v: "x 100%",
                t: "s",
                s: {
                  alignment: { horizontal: "center", vertical: "center" },
                },
              },
              {
                v: "= " + ecus_input?.formula_result?.toFixed(2) + "%",
                t: "s",
                s: {
                  alignment: { horizontal: "center", vertical: "center" },
                },
              },
              "",
              "",
              "",
              "",
              "",
            ],
            [
              "",
              "",
              {
                v: `${
                  deminimisData?.is_weight
                    ? "Tổng trọng lượng của thành phẩm"
                    : " Trị giá FOB của thành phẩm"
                }`,
                t: "s",
                s: {
                  border: {
                    top: { style: "thin", color: { rgb: "000000" } },
                  },
                },
              },

              {
                v: "",
                t: "s",
                s: {
                  border: {
                    top: { style: "thin", color: { rgb: "000000" } },
                  },
                },
              },
              {
                v: "",
                t: "s",
                s: {
                  border: {
                    top: { style: "thin", color: { rgb: "000000" } },
                  },
                },
              },
              {
                v: "",
                t: "s",
                s: {
                  border: {
                    top: { style: "thin", color: { rgb: "000000" } },
                  },
                },
              },
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          ])
        : []),
      formatListToNoneBorder([
        [renderResult(deminimisData, recalculate_args, ecus_input)],
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]),
      formatListToNoneBorder([
        "Công ty cam kết số liệu khai trên là đúng và xin chịu trách nhiệm trước pháp luật về thông tin, số liệu đã khai.",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]),
    ].map((item) =>
      item.map((itemChild) => {
        return typeof itemChild !== "object"
          ? {
              v: itemChild,
              t: "s",
              s: {
                border: borderFormatCell,
              },
            }
          : itemChild;
      })
    );

    const mergeFormular = (form_criteria, form_type, sedo) => {
      if (ecus_input?.select_form === "AI") {
        return [
          { row: data.length - 3, col: 1, rowspan: 1, colspan: 3 },
          { row: data.length - 4, col: 4, rowspan: 2, colspan: 1 },
          { row: data.length - 4, col: 5, rowspan: 2, colspan: 1 },
          { row: data.length - 4, col: 7, rowspan: 2, colspan: 1 },
          { row: data.length - 4, col: 8, rowspan: 2, colspan: 1 },
          { row: data.length - 4, col: 9, rowspan: 2, colspan: 1 },
        ];
      }
      if (
        recalculate_args?.form_criteria === "RVC" &&
        ecus_input?.is_direct_rvc
      ) {
        return [
          {
            row: data.length - 3,
            col: 1,
            rowspan: 1,
            colspan: 9,
          },
          {
            row: data.length - 5,
            col: 1,
            rowspan: 1,
            colspan: 9,
          },

          {
            row: data.length - 6,
            col: 10,
            rowspan: 2,
            colspan: 1,
          },

          {
            row: data.length - 4,
            col: 10,
            rowspan: 2,
            colspan: 1,
          },
          {
            row: data.length - 4,
            col: 11,
            rowspan: 2,
            colspan: 1,
          },
          {
            row: data.length - 4,
            col: 12,
            rowspan: 2,
            colspan: 1,
          },
        ];
      } else if (form_criteria === "RVC" && sedo === true) {
        return [
          { row: 15 + bangke_data?.length, col: 0, rowspan: 1, colspan: 2 },
          { row: 15 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 16 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 16 + bangke_data?.length, col: 0, rowspan: 1, colspan: 2 },
          { row: 16 + bangke_data?.length, col: 6, rowspan: 1, colspan: 2 },
          { row: 17 + bangke_data?.length, col: 0, rowspan: 1, colspan: 2 },
          { row: 17 + bangke_data?.length, col: 3, rowspan: 1, colspan: 5 },
          { row: 18 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 18 + bangke_data?.length, col: 6, rowspan: 1, colspan: 2 },
          { row: 18 + bangke_data?.length, col: 0, rowspan: 1, colspan: 2 },
          { row: 19 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 19 + bangke_data?.length, col: 0, rowspan: 2, colspan: 2 },
          { row: 19 + bangke_data?.length, col: 2, rowspan: 2, colspan: 1 },
          { row: 20 + bangke_data?.length, col: 3, rowspan: 1, colspan: 3 },
          { row: 19 + bangke_data?.length, col: 6, rowspan: 2, colspan: 1 },
          // { row: 19 + bangke_data?.length, col: 7, rowspan: 2, colspan: 1 },
        ];
      } else if (form_criteria === "RVC" && form_type === "EXW") {
        return [
          { row: 15 + bangke_data?.length, col: 0, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length, col: 2, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length, col: 3, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length + 2, col: 2, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length + 2, col: 3, rowspan: 2, colspan: 1 },
        ];
      } else if (form_criteria === "RVC" && form_type === "FOB") {
        return [
          { row: 15 + bangke_data?.length, col: 0, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length, col: 2, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length, col: 3, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length + 2, col: 2, rowspan: 2, colspan: 1 },
          { row: 15 + bangke_data?.length + 2, col: 3, rowspan: 2, colspan: 1 },
        ];
      } else if (form_criteria !== "RVC" && deminimisData?.is_deminimis) {
        return [
          {
            row: 15 + bangke_data?.length,
            col: 2,
            bottom: { width: 1, color: "black" },
          },
        ];
      } else return [];
    };

    const mergeCellsData = () => {
      return [...itemMap]
        .map((item, indx) => {
          return item[item.length - 1]
            ? null
            : {
                row: 14 + indx,
                col: 11,
                rowspan: 1,
                colspan: 2,
              };
        })
        .filter((item) => !!item);
    };

    const mergeCells = [
      { row: 0, col: 0, rowspan: 1, colspan: 13 },
      { row: 1, col: 0, rowspan: 1, colspan: 13 },
      { row: 2, col: 0, rowspan: 1, colspan: 13 },
      { row: 3, col: 10, rowspan: 1, colspan: 3 },
      { row: 4, col: 10, rowspan: 1, colspan: 3 },
      { row: 5, col: 10, rowspan: 1, colspan: 3 },
      { row: 6, col: 10, rowspan: 1, colspan: 3 },
      { row: 7, col: 10, rowspan: 1, colspan: 3 },
      { row: 9, col: 0, rowspan: 1, colspan: 13 },
      { row: 10, col: 0, rowspan: 3, colspan: 1 },
      { row: 10, col: 1, rowspan: 3, colspan: 1 },
      { row: 10, col: 2, rowspan: 3, colspan: 1 },
      { row: 10, col: 3, rowspan: 3, colspan: 1 },
      { row: 10, col: 4, rowspan: 3, colspan: 1 },
      { row: 10, col: 5, rowspan: 1, colspan: 3 },
      { row: 10, col: 8, rowspan: 3, colspan: 1 },
      { row: 10, col: 9, rowspan: 2, colspan: 2 },
      { row: 10, col: 11, rowspan: 2, colspan: 2 },
      { row: 11, col: 5, rowspan: 2, colspan: 1 },
      { row: 11, col: 6, rowspan: 1, colspan: 2 },
      ...mergeCellsData(),
      // Add more merged cells as needed
      ...mergeFormular(
        recalculate_args?.form_criteria,
        recalculate_args?.form_type,
        !!recalculate_args?.progress_price
      ),
      {
        row: data.length - 1,
        col: 0,
        rowspan: 1,
        colspan: 13,
      },
      {
        row: data.length - 2,
        col: 0,
        rowspan: 1,
        colspan: 13,
      },
      deminimisData?.is_deminimis && {
        row: data.length - 4,
        col: 2,
        rowspan: 1,
        colspan: 4,
      },
      deminimisData?.is_deminimis && {
        row: data.length - 3,
        col: 2,
        rowspan: 1,
        colspan: 4,
      },
      deminimisData?.is_deminimis && {
        row: data.length - 4,
        col: 1,
        rowspan: 2,
        colspan: 1,
      },
      deminimisData?.is_deminimis && {
        row: data.length - 4,
        col: 6,
        rowspan: 2,
        colspan: 1,
      },
      deminimisData?.is_deminimis && {
        row: data.length - 4,
        col: 7,
        rowspan: 2,
        colspan: 1,
      },
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    worksheet["!merges"] = mergeCells
      .filter((item) => item)
      .map((merge) => {
        return {
          s: { r: merge.row, c: merge.col },
          e: {
            r: merge.row + merge.rowspan - 1,
            c: merge.col + merge.colspan - 1,
          },
        };
      });
    const colWidths = [
      { width: 10 },
      { width: 40 },
      { width: 10 },
      { width: 30 },
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 10 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];

    worksheet["!cols"] = colWidths;

    const sheetName = `${ecus_input?.ecus_code}`;
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    XLSX.writeFile(workbook, "BangkeExport.xlsx");
  };

  const { mutateAsync, isLoading } = usePostDeduction();
  const { mutateAsync: mutateRollback, isLoading: isLoadingRolback } =
    usePostRollback();

  const handleDeduction = async () => {
    await mutateAsync(DataBangke);
  };

  const handleRollback = async () => {
    await mutateRollback(DataBangke);
  };
  return (
    <Modal
      style={{ top: 44 }}
      open={isVisible}
      bodyStyle={{
        height: "85vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      onCancel={closeModal}
      footer={null}
      width={"90%"}
      maskClosable={true}
      title={messages["bangke.viewDetail"]}
      className="modal"
      destroyOnClose
    >
      <h3>{DataBangke?.ecus_input?.ecus_code}</h3>
      <div style={{ height: "100%" }}>
        {!DataBangke?.ecus_input?.is_direct_rvc ? (
          <Bangke
            bangkeData={DataBangke}
            styleTable={{ height: "70vh" }}
            deminimisData={{
              is_weight: DataBangke?.is_weight,
              formula_result: DataBangke?.ecus_input?.formula_result,
              formula_status: DataBangke?.ecus_input?.formula_status,
              is_deminimis: DataBangke?.is_deminimis,
            }}
          />
        ) : (
          <BangkeRVCDirect
            bangkeData={DataBangke}
            styleTable={{ height: "70vh" }}
            deminimisData={{
              is_weight: DataBangke?.is_weight,
              formula_result: DataBangke?.ecus_input?.formula_result,
              formula_status: DataBangke?.ecus_input?.formula_status,
              is_deminimis: DataBangke?.is_deminimis,
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "8px",
          paddingTop: "24px",
          height: "100%",
          alignItems: "end",
        }}
      >
        <AppButtonActions
          scopes={[PERMISSIONS.EDIT_BANGKE_HISTORY]}
          title={messages["bangke.rollback"]}
          type="default"
          disabled={!DataBangke?.is_deducted}
          onClick={handleRollback}
        />

        <AppButtonActions
          scopes={[PERMISSIONS.EDIT_BANGKE_HISTORY]}
          title={messages["bangke.deduction"]}
          danger
          disabled={DataBangke?.is_deducted}
          onClick={handleDeduction}
        />

        <Button type="primary" onClick={ExportAllToExcel}>
          <DownloadOutlined />
          {messages["common.export"]}
        </Button>
      </div>
    </Modal>
  );
});

export default ViewHistoryModal;
